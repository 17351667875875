import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

Swiper.use([Navigation]);

jQuery(document).ready(function($) {

	if ( typeof Swiper !== "undefined" ){
        
		new Swiper('.testimonial-swiper', {
            modules: [Autoplay, Pagination],
			slidesPerView: "1",
            loop: true,
            autoplay: {
                delay: 10000,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
		});

	}

});
