import mixitup from 'mixitup';
var container = document.querySelector('.filter-list');

if (container) {
    var mixer = mixitup(container, {
        selectors: {
            target: '.profile'
        },
        animation: {
            duration: 250,
            nudge: true,
            reverseOut: false,
            // effects: "fade translateZ(-100px)"
        }
    });
}
